<template>
    <el-pagination
        background
        v-bind="$attrs"
        :current-page="pager.pageNo"
        :page-size="pager.pageSize"
        :page-sizes="[100, 200, 300, 500]"
        layout="total, prev, pager, next, sizes, jumper"
        class="flex-ju-end mt-20"
        @size-change="$onSizeChange"
        @current-change="$onCurrentChange"
        v-on="$listeners"
    >
    </el-pagination>
</template>
<script>
export default {
    name: 'comPagination',
    props: {
        page: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            pager: {
                pageNo: 1,
                pageSize: 200
            }
        };
    },
    watch: {
        page(val) {
            this.pager = val;
        }
    },
    methods: {
        // 分页
        $onSizeChange(val) {
            this.pager.pageSize = val;
            this.pager.pageNo = 1;
            this.$emit('change', this.pager);
        },
        $onCurrentChange(val) {
            this.pager.pageNo = val;
            this.$emit('change', this.pager);
        }
    }
};
</script>
